.MainPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.MainPageLocoPanel {
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.MainPageLocoPanel>* {
    flex: 1 1 30px;
}
