.StationByName {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.StationByNameTitle {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13px;
}