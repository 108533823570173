.Arrow {
    display: flex;
    position: relative;
    align-items: center;
    gap: 6px;
    left: 10%;
    width: 100%;
}

.Kek {
    position: relative;
    left: 30px;
    display: inline;
}