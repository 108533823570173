.LocoCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.LocoCardVolte {
    display: flex;
    width: 100%;
}

.LocoCardVolteLabel {
    margin-left: 50px;
}

.LocoCardVolteValue{
    margin-right: auto;
    margin-left: 10px;
}

.LocoCardPosition {
    display: flex;
    gap: 15px;
}

.LocoCardPositionValue {
    opacity: 0.7;
    background-color: #c4c4c4;
    padding: 0 15px;
    border-radius: 10px;
}

.LocoCardReverse {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
}

.LocoCardPanel {
    display: flex;
    gap: 8px;
    margin-bottom: 5px;
}

.LocoCardPanelBtn {
    padding: 15px 9px;
}

.LocoCardVersion {
    font-size: 20px;
}

.LocoCardStatus {
    display: flex;
    align-items: center;
    gap: 10px;
}

.LocoCardStopBtn {
    padding: 15px 20px;
}

.LocoCardReverseName {
    margin-left: 10px;
    opacity: 0.7;
    width: 100px;
    background-color: #c4c4c4;
    padding: 0 15px;
    border-radius: 10px;
}