.Input {
    background: none;
    font-size: 24px;
    outline: none;
    border: none;
    border-bottom: 2px solid #b8b8b8;
    border-radius: 20px;
    box-sizing: border-box;
    padding-left: 15px;
}

.Input:focus {
    background: #adadad;
}