.status {
    width: 20px;
    height: 20px;
    border-radius: 10px;
}

.statusActive {
    background: #00ff00;
}

.statusInactive {
    background: #ff0000;
}