.LocoAdminPageLogin {
    min-width: 100%;
}


.LocoAdminPagePanel {
    padding-top: 20px;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}