.LocoPage {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.LocoPageLogin {
    min-width: 100%;
}

p {
    margin: 0px;
}

.LocoPageCard {
    margin: 15px;
}

img {
    display: block;
    max-width: 100%;
    max-height: 100%;
}