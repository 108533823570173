.AuthLogin {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    flex-direction: column;
    width: 230px;
}

.AuthLoginStatus {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}
