.Button {
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    font-size: 30px;
}

.ButtonNormal {
    background: #dfdfdf;
    border: 2px solid #686868;
}

.ButtonClicked {
    background: #adadad;
    border: 2px solid #686868;
}

.ButtonClear {
    background: none;
    border: none;
}

.Button:hover {
    opacity: 0.65;
}