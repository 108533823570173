.LocoBtnStatus {
    display: flex;
    gap: 15px;
    justify-content: center;
    align-items: center;
}

.LocoBtn {
    display: flex;
    flex-direction: column;
    width: 170px;
}

.LocoBtnLight {
    opacity: 0.5;
}
